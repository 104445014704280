<template>
  <header
    :class="{ 'bg-[#e6eef1]': width <= minWidth }"
    class="flex flex-col justify-between items-center sm:my-6 sm:mx-12"
  >
    <div
      class="flex w-full items-center justify-between pb-2 sm:py-2 sm:border-b border-b-[0.5px] border-black"
    >
      <div
        class="sm:tracking-wider tracking-widest mx-3 sm:mx-0 space sm:text-3xl font-black sm:font-black default-font py-2"
      >
        <nuxt-link to="/">
          <img
            class="sm:w-[127px] sm:h-auto w-[69px] h-auto"
            src="~/assets/logo.png"
            alt=""
          />
        </nuxt-link>
      </div>
      <nav class="hidden md:flex space-x-4 ml-3">
        <ul class="header-nav flex justify-between font-normal text-lg">
          <li class="mx-6 sm:font-base">
            <nuxt-link :to="localPath('/')">About</nuxt-link>
          </li>
          <li class="mx-6 sm:font-base">
            <nuxt-link :to="localPath('/service')">Service</nuxt-link>
          </li>
          <li class="mx-6 sm:font-base">
            <nuxt-link :to="localPath('/works/aww')">Work</nuxt-link>
          </li>
          <li class="mx-6 sm:font-base">
            <nuxt-link :to="localPath('/contact')">Contact</nuxt-link>
          </li>
          <li
            class="select-none rounded-full border border-black flex justify-center text-xs ml-6"
          >
            <NuxtLink
              :to="switchLocalePath('en')"
              :class="locale === 'en' ? 'nav-link-active' : ''"
              class="px-3 py-1 cursor-pointer"
              >English</NuxtLink
            >
            <NuxtLink
              :to="switchLocalePath('ja')"
              :class="locale === 'ja' ? 'nav-link-active' : ''"
              class="px-3 py-1 cursor-pointer"
              >日本語</NuxtLink
            >
          </li>
        </ul>
      </nav>

      <button
        @click="toggleMenu"
        class="md:hidden mx-3 sm:mx-0 z-50 text-black"
      >
        <svg
          v-if="menuHidden"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            y="10"
            rx="1"
            ry="1"
            width="30"
            height="3"
            fill="currentColor"
          ></rect>
          <rect
            x="4"
            y="24"
            rx="1"
            ry="1"
            width="30"
            height="3"
            fill="currentColor"
          ></rect>
        </svg>

        <svg
          v-else
          class="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div
      :class="menuHidden ? 'hidden' : ''"
      class="relative ease-in overflow-y-hidden pb-16 duration-300 w-full left-0 top-16 bg-[#E7EEF1] text-black"
    >
      <nuxt-link
        class="block font-black second-font px-2 text-6xl"
        @click="navigateTo('/')"
        :to="localPath('/')"
      >
        About
      </nuxt-link>
      <nuxt-link
        class="block second-font font-black px-2 text-6xl"
        :to="localPath('/service')"
        @click="navigateTo('/service')"
      >
        Services
      </nuxt-link>
      <nuxt-link
        class="block second-font font-black px-2 text-6xl"
        :to="localPath('/work')"
        @click="navigateTo('/work')"
        >Work</nuxt-link
      >
      <nuxt-link
        class="block second-font font-black px-2 text-6xl"
        :to="localPath('/contact')"
        @click="navigateTo('/contact')"
      >
        Contact
      </nuxt-link>
      <li
        class="flex w-[117.2px] mx-[10px] my-3 list-none rounded-full border border-black text-[12px]"
      >
        <NuxtLink
          :to="switchLocalePath('en')"
          :class="locale === 'en' ? 'nav-link-active' : ''"
          class="px-3 py-1 cursor-pointer"
          >English</NuxtLink
        >
        <NuxtLink
          :to="switchLocalePath('ja')"
          :class="locale === 'ja' ? 'nav-link-active' : ''"
          class="px-3 py-1 cursor-pointer"
          >日本語</NuxtLink
        >
      </li>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";
const { t, locale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const localPath = useLocalePath();
const menuHidden = ref(true);

const $emit = defineEmits(["toggleMenu"]);

const toggleMenu = () => {
  menuHidden.value = !menuHidden.value;
  $emit("toggleMenu", menuHidden.value);
};

const navigateTo = (link) => {
  toggleMenu();
};

let width = ref(0);
const minWidth = 640;

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  updateWidth();
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>
<style lang="scss" scoped>
.nav-link-active {
  @apply bg-black text-white rounded-full;
}
</style>
