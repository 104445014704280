<template>
  <AppHeader @toggle-menu="toggleMenu" />
  <slot v-if="show" />
  <AppGetInTouch v-if="!show" />
  <AppFooter />
</template>
<script setup lang="ts">
const show = ref(true);

const toggleMenu = (value: boolean) => {
  show.value = value;
};
</script>
