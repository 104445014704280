<template>
  <div class="footer sm:px-40 sm:mt-24 px-10 mt-10 relative w-full overflow-x-hidden">
    <img
      class="max-w-2xl sm:-rotate-[25deg] sm:scale-150 scale-75 sm:w-full sm:h-full w-60 h-60 absolute sm:right-0 sm:top-0 -right-8 sm:-mt-[25rem] sm:mr-[12rem] -mt-[3rem] -z-10"
      src="../assets/images/footer-c.png"
      alt=""
    />

    <div class="footer-links sm:flex justify-between sm:-ml-[2.8rem]">
      <dl v-if="width > minWidth">
        <dt class="font-bold mb-6 sm:text-xl">Services</dt>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <nuxt-link :to="localPath('/service')">Branding</nuxt-link>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <nuxt-link :to="localPath('/service')">Marketing</nuxt-link>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <a href="#">Technology Solution</a>
          <nuxt-link :to="localPath('/service')">Technology Solution</nuxt-link>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <nuxt-link :to="localPath('/service')">Consulting</nuxt-link>
        </dd>
      </dl>

      <dl v-if="width > minWidth">
        <dt class="font-bold mb-6 sm:text-xl">Company</dt>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <a href="#">About</a>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <nuxt-link :to="localPath('/contact')">Contact</nuxt-link>
        </dd>
      </dl>

      <dl v-if="width > minWidth">
        <dt class="font-bold mb-6 sm:text-xl">Social</dt>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <a href="#">Twitter</a>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <a href="#">Instagram</a>
        </dd>
        <dd class="text-sm font-light mb-1 sm:text-xl">
          <a href="#">LinkedIn</a>
        </dd>
      </dl>

      <dl>
        <dt class="font-bold sm:mb-6 mb-4 text-xs sm:text-xl">Office</dt>
        <dd class="text-xs mb-1 sm:text-xl sm:font-normal font-b">New York</dd>
        <dd class="text-xs font-normal mb-1 sm:text-sm">
          <a
            class="custom-underline"
            href="https://www.google.com/maps/place/1460+Broadway+14th+Floor,+New+York,+NY+10036+%E3%82%A2%E3%83%A1%E3%83%AA%E3%82%AB%E5%90%88%E8%A1%86%E5%9B%BD/data=!4m2!3m1!1s0x89c259ab29101617:0xffd0d0af728e2f77?sa=X&ved=2ahUKEwjLjo3w__6AAxWWGogKHTZjDpIQ8gF6BAgjEAA&ved=2ahUKEwjLjo3w__6AAxWWGogKHTZjDpIQ8gF6BAgkEAI"
            target="_blank"
          >
            1460 Broadway 14th Floor,New York
          </a>
        </dd>
      </dl>

      <dl>
        <dd class="text-sm font-light mb-1 leading-8 block sm:h-10 h-2"></dd>
        <dd class="font-bold mb-1 text-xs sm:text-xl sm:font-normal">Tokyo</dd>
        <dd class="font-normal mb-1 decoration-1 sm:text-sm text-xs">
          <a
            class="custom-underline"
            href="https://www.google.com/maps/place/%E3%80%92106-0032+%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%AF%E5%8C%BA%E5%85%AD%E6%9C%AC%E6%9C%A8%EF%BC%97%E4%B8%81%E7%9B%AE%EF%BC%97%E2%88%92%EF%BC%97+%E3%83%88%E3%83%A9%E3%82%A4%E3%82%BB%E3%83%96%E3%83%B3%E3%83%AD%E3%83%83%E3%83%9D%E3%83%B3%E3%82%AE+8f/data=!4m2!3m1!1s0x60188b78e24ffd19:0x137864587f03a691?sa=X&ved=2ahUKEwid4K-h__6AAxV8SPUHHZ67A5MQ8gF6BAgTEAA&ved=2ahUKEwid4K-h__6AAxV8SPUHHZ67A5MQ8gF6BAgUEAI"
            target="_blank"
            >東京都港区六本木7-7-7-8F</a
          >
        </dd>
      </dl>

      <dl>
        <dd class="text-sm font-light mb-1 leading-5 block sm:h-10 h-3"></dd>
        <dd class="font-bold sm:font-normal mb-1 text-xs sm:text-xl">TEL</dd>
        <dd class="font-normal mb-1 text-xs sm:text-sm">
          <a class="custom-underline" href="tel:0368246904" target="_blank"
            >03-6824-7904</a
          >
        </dd>
      </dl>
    </div>

    <hr class="sm:border-b border-t-0 border-black sm:mt-16 mt-3 -mx-16" />

    <div class="sm:mt-24 mt-8">
      <div class="flex sm:justify-between justify-evenly w-40 mx-auto">
        <a target="_blank" href="https://www.instagram.com/ifstage"
          ><img class="w-4 sm:w-6" src="../assets/images/ins.png" alt=""
        /></a>
        <a target="_blank" href="#"
          ><img class="w-4 sm:w-6" src="../assets/images/twi.png" alt=""
        /></a>
        <a target="_blank" href="#"
          ><img class="w-4 sm:w-6" src="../assets/images/lin.png" alt=""
        /></a>
      </div>
      <p class="text-center text-xs sm:mt-10 mt-5">
        © {{ new Date().getFullYear() }} IFSTAGE. All Rights Reserved.
      </p>
    </div>

    <div class="sm:mt-56 mt-20"></div>
  </div>
</template>

<script lang="ts" setup>
let width = ref(0);
const minWidth = 640;
const localPath = useLocalePath();

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  updateWidth();
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>

<style lang="scss">
.custom-underline {
  @apply border-b w-auto border-gray-300 sm:py-0.5 py-[1px];
}
</style>
